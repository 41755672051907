/* You can add global styles to this file, and also import other style files */
/* @import url('https://fonts.googleapis.com/css?family=Rubik');
@import url('https://fonts.googleapis.com/css?family=Lato'); */


.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.form-control {
  border: 1px solid #A2AFBE !important;
  font-size: 13px !important;
}

body {
  font-size: 13px !important;
  line-height: 1;
}

::placeholder {
  color: #8292A4 !important;
}

.input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
  border-radius: .25em !important;
}

/* table global style */

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #fff;
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: #EBF2FF;
}


.modal-content {
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.17);
  background-color: #FFFFFF;
  width: 100%;
  height: auto;
}

.modal-title {
  color: #fff;
  font-family: 'Lato';
  letter-spacing: 0.05px;
  line-height: 32px;
  font-size: 18px;
}

.modal-header {
  background-color: #8292A4;
  color: white;
  border-radius: 8px 8px 0 0;
  height: 50px;
  padding: 8px 16px 8px 16px;
  font-family: 'Lato';
}

.modal-close {
  float: right;
  line-height: 32px;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  font-family: 'Rubik';
  padding: 16px;
  line-height: 20px;
}

.modal-footer {
  justify-content: flex-start;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0 !important;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

/* global button style */

.btn-credhub {
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.15);
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 3px;
  height: 40px;
  width: 100px;
  cursor: pointer;
}

.btn-credhub:disabled {
  background-color: #E4E4E4 !important;
}

.credhub-secondary {
  border: 1px solid #A2AFBE;
  background-color: #FFFFFF;
  color: #8292A4;
}

.credhub-primary {
  border: 0px solid #A2AFBE;
  background-color: #4389A5;
  color: #FFFFFF;
}

  .credhub-primary:hover {
    background-color: #F5C359;
  }

.credhub-secondary:hover {
  border: 1px solid #8292A4;
}

.credhub-link-btn {
  background: none;
  height: 36px;
  width: 132px;
  border: none;
  color: #1962E4;
  cursor: pointer;
}

.credhub-link-btn:hover {
  /* height: 40px;
  width: 132px; */
  border-radius: 3px;
  background-color: #E9F1FF;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.15);
}

/* global style for section column */

.credhub-section-col {
  padding: 0;
}

/* global style for breadcrumb */

.credhub-breadcrumb {
  color: #0A273C;
  font-family: 'Rubik';
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  background: transparent !important;
  padding-left: 24px;
}

.breadcrumb-item .active {
  color: #97a1ab;
  font-weight: 300 !important;
}

/* global style for tooltip */

.credhub-tooltip .tooltip-inner {
  height: 32px;
  min-width: 199px;
  background-color: #0A273C;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  color: #FFFFFF;
  font-family: 'Rubik';
  font-size: 13px;
  line-height: 24px;
}

.credhub-tooltip .arrow::before {
  border-bottom-color: #0A273C;
  margin-left: 40px;
}

.credhub-tooltip-lg .tooltip-inner {
  /* height: 32px; */
  min-width: 400px;
  background-color: #0A273C;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  color: #FFFFFF;
  font-family: 'Rubik';
  font-size: 13px;
  line-height: 24px;
  text-align: left;
}

/* search box with div style */

.credhub-search-col {
  display: inline-flex;
}

.credhub-icon-search {
  margin-top: 8px;
  margin-left: -28px;
  color: #8292A4;
  cursor: pointer;
  z-index: 99;
}



select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(./assets/img/arrow_drop_down-24px.svg) right / 20px no-repeat #fff;
  font-family: 'Rubik';
  font-size: 14px;
}

/* For IE 9, 10 ,11 */
select::-ms-expand {
  display: none;
}

/* End of For IE 9, 10 ,11 */

select:required:invalid {
  color: #8292A4;
}


option[value=""] {
  color: #ada8a8;
}

option {
  color: black;
  position: relative;
}


/* bold font global style */

.credhub-bold-font {
  font-weight: bold !important;
}

.td-text-right {
  text-align: right;
  padding-right: 24px !important;
  max-width: 80px !important;
}



/* credit beureu specific css */

.equifax-card {
  height: 12px;
  margin-bottom: 2px;
  margin-left: 8px;
}

.transunion-card {
  height: 18px;
  margin-top: -13px;
  margin-left: 8px;
}

.experian-card {
  height: 18px;
  margin-top: -8px;
  margin-left: 8px;
}

.innovis-card {
  height: 18px;
  margin-top: -4px;
  margin-left: 10px;
}


/* chart tooltip css */

.arrow_box~* {
  border: none;
}

.arrow_box {
  position: relative;
  height: auto;
  width: auto;
  font-family: 'Rubik';
  font-size: 10px;
  background-color: #0A273C;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  border: none !important;
  color: white;
  text-align: center;
  /* line-height: 24px !important; */
  padding: 8px 8px;
}

.arrow_box:after, .arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: transparent;
  border-top-color: #0A273C;
  border-width: 3px;
  margin-left: -3px;
}

.arrow_box:before {
  border-color: transparent;
  border-top-color: #0A273C;
  border-width: 4px;
  margin-left: -4px;
}



li.disabled {
  pointer-events: none;
}

li.disabled a {
  color: gray !important;
}

.inactive-color {
  color: #8292A4;
}


/* custom checkbox css */

.rectangle-checkbox {
  height: 16px;
  width: 16px;
  border: 1px solid #A2AFBE;
  border-radius: 4px;
  background: linear-gradient(180deg, #DCDCDC 0%, #FFFFFF 100%);
  cursor: pointer;
}

.rectangle-checkbox-checked {
  cursor: pointer;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  /* background: linear-gradient(0deg, #F87674 0%, #EF4140 100%); */
  background: #4389A5;
}

.disable-background {
  background: linear-gradient(0deg, rgb(167, 175, 184) 0%, #8292A4 100%);
}

/* custom checkbox end */

/* for mandatory field indication */

input.submitted.ng-invalid,
select.submitted.ng-invalid {
  border: 1px solid #f00 !important;
}

/* for mandatory field indication end */

/*---------------Grid custom Check box ---------------------*/

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -11px;
  left: 8px;
  height: 11px;
  width: 11px;
  background-color: #FFFFFF;
  outline: 1px solid #080808;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #4389A5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 3px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* --------------------custom checkbox code ends -----------------------*/
.gridheaderwidth {
  min-width: 3px;
}

.threeverticaldotdropdown:after {
  content: '\2807';
  font-size: 2em;
  width: 20px;
  height: 20px;
  color: rgb(130, 128, 128);
  margin-left: 403px;
  position: relative;
}

.dropdown-toggle::after {
  content: initial;
}

.actions-text {
  margin-bottom: -35px;
  margin-left: 352px;
  font-weight: 500;
  font-size: 15px;
  color: dimgrey;
  position: relative;
}

.dropdownmenu-text {
  font-size: 17px;
  color: #888484;
  ;
}

.disableclick {
  pointer-events: none;
}

.carousel-indicators button {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 25px;
  height: 1px;
  margin-right: 3px;
  margin-left: 3px;
  margin-bottom: 10px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #dee2e6b0;
  background-clip: padding-box;
  border: 0.2px solid #00000024;
  transition: opacity .6s ease;
}

.carousel-indicators .active {
  opacity: 1;
  background-color: white;
}

a {
  color: #4389A5;
}